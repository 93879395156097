@mixin transition($s){
  transition: $s;
  -webkit-transition: $s;
  -moz-transition: $s;
  -ms-transition: $s;
  -o-transition: $s;
  -icab-transition: $s;
  -khtml-transition: $s;
}

@mixin trans{
  @include transition($at all ease);
}

@mixin transf{
  @include transition($fat all ease);
}

@mixin fill{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

@mixin placeholder($color: #ccc, $font-size: 14px) {
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $color;
    font-size: $font-size;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $color;
    font-size: $font-size;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: $color;
    font-size: $font-size;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $color;
    font-size: $font-size;
  }
}

@mixin flex-gap($row, $col) {
  row-gap: 0;
  column-gap: 0;

  margin: calc(#{$row} / -2) calc(#{$col} / -2);

  & > * {
    margin: calc(#{$row} / 2) calc(#{$col} / 2);
  }
}

@mixin row-gap($x) {
  //row-gap: $x !important;
  row-gap: 0;

  & > * {
    margin-top: $x;

    &:first-child {
      margin-top: 0;
    }
  }
}

@mixin column-gap($x) {
  column-gap: 0;

  & > * {
    margin-left: $x;

    &:first-child {
      margin-left: 0;
    }
  }
}
