@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
// app global css in SCSS form

 //fonts
@font-face {
  font-family: Montserrat;
  src: url(/fonts/montserrat.woff2);
}

body {
  font-family: $font-second;
  min-width: auto;

  &.screen--xs {
    width: $site-width-xs;
    min-width: 460px;
  }

  &.screen--sm {
    width: $site-width-sm;
    min-width: 460px;
  }

  &.screen--md {
    //width: $site-width-md;
    min-width: $site-width-md;
  }

  &.screen--lg {
    //width: $site-width-lg;
    min-width: $site-width-lg;
  }

  &.screen--xl {
    //width: $site-width-xl;
    min-width: $site-width-lg;
  }
}


//-----------------------


@import "./_helpers";
@import "./es/main-es";

@import "./main";
@import "./templates/_all";
