// $

//---------------------------
h1 {
  font-size: 44px;
  font-weight: 600;
  font-family: $font-pro;
  margin-top: 0.1em;
  margin-bottom: 1.4em;
  line-height: 1.4em;
}

h2 {
  font-size: 22px;
  color: $dark;
  font-weight: 600;
  line-height: 1.4;

  border-bottom: 1px solid $primary;
  //display: inline-block;
}

h3 {
  font-size: 18px;
  color: $dark;
  font-weight: 600;
  line-height: 1.4;
  padding-bottom: 10px;

  //border-bottom: 1px solid $dark;
  //display: inline-block;


}
//---------------------------


.es-h {
  border-bottom: 2px solid rgba(119,119,119,.17);
  font-weight: 600;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;

  &.es--h1 {

  }

  &.es--h2 {

  }

  &.es--h3 {
    font-size: 18px;
  }

  .es-h--content, h1, h2, h3, h4, h5, h6 {
    text-align: center;
    padding-bottom: 8px;
    border-bottom: 2px solid $primary;
    margin-bottom: -2px;

    &h3 {
      font-size: 18px;
    }
  }
}

//------------------------------------------

.es-title {
  color: $dark;
  line-height: 1.4;
  margin-bottom: 1.2em;
  //margin-top: 1em;

  &.es--xs {

  }

  &.es--sm {
    font-size: 14px;
    color: $my-dark;
  }

  &.es--md {
    font-size: 22px;
    font-weight: 600;
  }
}


//------------------------------------------

table.es-table {
  font-size: 14px;
  color: $my-dark;

  tbody {

    tr {

      td {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid $my-grey;
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }

  &.es--first-strong {
    tbody {

      tr {

        td {
          &:first-child {
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 600;
          }
        }
      }
    }
  }
}

//------------------------------------------
//------------------------------------------
//------------------------------------------



.es-table-list {
  margin-top: 8px;

  .es--title {
    padding-bottom: 10px;
    //margin-bottom: 10px;
    text-align: left;
    border-bottom: 1px solid $my-grey;
    font-weight: 600;
    line-height: 1.4;
    font-size: 12px;
    text-transform: uppercase;
  }

  .es--item {
    font-size: 14px;
    text-align: left;
    color: $my-dark;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $my-grey;

    &:last-child {
      border-bottom: none;
    }
  }
}

//---------------- PRE_COMPONENTS

.p-ship-block {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  @include column-gap(15px);

  body.screen--xs &, body.screen--sm & {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    @include column-gap(0);
    @include row-gap(15px);
  }

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;

    body.screen--xs &, body.screen--sm & {
      min-height: 227px;
    }
  }

  &__body {

  }
}
