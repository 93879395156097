.top-contacts-panel {

  .main-row {
    margin-bottom: 24px;
  }

  .items-row {
    margin-bottom: 16px;
  }

  .email-row {
    margin: 16px 0;
  }

  .col {

    .top-contacts-panel-item {
      color: $dark;
      display: flex;
      align-items: center;
      padding: 8px 0;

      a {
        color: $dark;
        display: flex;
        text-decoration: none;
        align-items: center;
        @include trans;

        &:hover {
          color: $primary;
        }
      }

      i {
        color: $dark;
        margin-right: 8px;
        font-size: 16px;

        &.red {
          color: $my-red;
        }

        &.grey {
          color: $my-dark;
        }
      }
    }

    &.main-col {

      .top-contacts-panel-item {
        //padding: 16px 0 24px;
      }
    }

    &.col-phone {

    }

    &.col-info {
      padding-left: 32px;
    }
  }

}
