.color-my-red {
  color: $my-red !important;
}



//-------------btns
.my-btn-gray {
  background-color: #e8e8e8; text-transform: none !important;
}
