@font-face {
  font-family: Montserrat;
  src: url(/fonts/montserrat.woff2);
}
body {
  font-family: Verdana, Geneva, sans-serif, sans-serif;
  min-width: auto;
}
body.screen--xs {
  width: 100%;
  min-width: 460px;
}
body.screen--sm {
  width: 100%;
  min-width: 460px;
}
body.screen--md {
  min-width: 1020px;
}
body.screen--lg {
  min-width: 1200px;
}
body.screen--xl {
  min-width: 1200px;
}

.color-my-red {
  color: #cc0024 !important;
}

.my-btn-gray {
  background-color: #e8e8e8;
  text-transform: none !important;
}

h1 {
  font-size: 44px;
  font-weight: 600;
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  margin-top: 0.1em;
  margin-bottom: 1.4em;
  line-height: 1.4em;
}

h2 {
  font-size: 22px;
  color: #1D1D1D;
  font-weight: 600;
  line-height: 1.4;
  border-bottom: 1px solid #1279b9;
}

h3 {
  font-size: 18px;
  color: #1D1D1D;
  font-weight: 600;
  line-height: 1.4;
  padding-bottom: 10px;
}

.es-h {
  border-bottom: 2px solid rgba(119, 119, 119, 0.17);
  font-weight: 600;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}
.es-h.es--h3 {
  font-size: 18px;
}
.es-h .es-h--content, .es-h h1, .es-h h2, .es-h h3, .es-h h4, .es-h h5, .es-h h6 {
  text-align: center;
  padding-bottom: 8px;
  border-bottom: 2px solid #1279b9;
  margin-bottom: -2px;
}
.es-h .es-h--contenth3, .es-h h1h3, .es-h h2h3, .es-h h3h3, .es-h h4h3, .es-h h5h3, .es-h h6h3 {
  font-size: 18px;
}

.es-title {
  color: #1D1D1D;
  line-height: 1.4;
  margin-bottom: 1.2em;
}
.es-title.es--sm {
  font-size: 14px;
  color: #777777;
}
.es-title.es--md {
  font-size: 22px;
  font-weight: 600;
}

table.es-table {
  font-size: 14px;
  color: #777777;
}
table.es-table tbody tr td {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e8e8e8;
}
table.es-table tbody tr:last-child td {
  border-bottom: none;
}
table.es-table.es--first-strong tbody tr td:first-child {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
}

.es-table-list {
  margin-top: 8px;
}
.es-table-list .es--title {
  padding-bottom: 10px;
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
  font-weight: 600;
  line-height: 1.4;
  font-size: 12px;
  text-transform: uppercase;
}
.es-table-list .es--item {
  font-size: 14px;
  text-align: left;
  color: #777777;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e8e8e8;
}
.es-table-list .es--item:last-child {
  border-bottom: none;
}

.p-ship-block {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  column-gap: 0;
}
.p-ship-block > * {
  margin-left: 15px;
}
.p-ship-block > *:first-child {
  margin-left: 0;
}
body.screen--xs .p-ship-block, body.screen--sm .p-ship-block {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  column-gap: 0;
  row-gap: 0;
}
body.screen--xs .p-ship-block > *, body.screen--sm .p-ship-block > * {
  margin-left: 0;
}
body.screen--xs .p-ship-block > *:first-child, body.screen--sm .p-ship-block > *:first-child {
  margin-left: 0;
}
body.screen--xs .p-ship-block > *, body.screen--sm .p-ship-block > * {
  margin-top: 15px;
}
body.screen--xs .p-ship-block > *:first-child, body.screen--sm .p-ship-block > *:first-child {
  margin-top: 0;
}
.p-ship-block__img {
  display: flex;
  justify-content: center;
  align-items: center;
}
body.screen--xs .p-ship-block__img, body.screen--sm .p-ship-block__img {
  min-height: 227px;
}
section {
  width: 100%;
  display: flex;
  justify-content: center;
}
section > .section-wrap {
  width: 1200px;
}
body.screen--xs section > .section-wrap {
  width: 100%;
}
body.screen--sm section > .section-wrap {
  width: 100%;
}
body.screen--md section > .section-wrap {
  width: 1020px;
}
body.screen--lg section > .section-wrap {
  width: 1200px;
}
body.screen--xl section > .section-wrap {
  width: 1200px;
}
section > .section-wrap-full {
  width: 100%;
}

label .q-field__control:after, .q-field--outlined .q-field__control:after {
  border-width: 1px !important;
}

.q-btn {
  text-transform: none !important;
}

.my-table {
  border-radius: 2px !important;
  border-color: #e8e8e8 !important;
}
.my-table th {
  text-align: center;
}

.sticky-column-table {
  width: 100%;
  max-width: 956px;
  border-radius: 2px !important;
  border-color: #e8e8e8 !important;
}
.sticky-column-table.is-full {
  max-width: 1200px;
}
.sticky-column-table thead tr:first-child th:first-child {
  background-color: #fff;
  border-right: 1px solid #e8e8e8;
}
.sticky-column-table td:first-child {
  background-color: #fff;
  border-right: 1px solid #e8e8e8;
}
.sticky-column-table th:first-child,
.sticky-column-table td:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
}

.q-checkbox.disabled {
  opacity: 0.45 !important;
}

label.q-input .q-field__inner .q-field__control.q-field__control::before {
  border-color: #e8e8e8 !important;
}
label.q-input .q-field__inner .q-field__control.q-field__control::after {
  border-color: #e8e8e8 !important;
}

.owl-dot {
  opacity: 0.8;
  display: block;
  width: 12px;
  height: 12px;
  border: 2px solid #e8e8e8;
  border-radius: 50%;
  transition: all 0.25s ease;
}
.owl-dot.is-active {
  border-color: #1D1D1D;
  background-color: #1D1D1D;
}

.t-link {
  color: inherit;
  text-decoration: none;
  transition: 0.4s all ease;
  -webkit-transition: 0.4s all ease;
  -moz-transition: 0.4s all ease;
  -ms-transition: 0.4s all ease;
  -o-transition: 0.4s all ease;
  -icab-transition: 0.4s all ease;
  -khtml-transition: 0.4s all ease;
}
.t-link:hover {
  color: #2196f3;
}

.title-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 24px 0 16px;
}
.title-container .title-line {
  display: block;
  flex: 1 1 auto;
  border-bottom: 1px solid rgba(129, 129, 129, 0.2);
}
.title-container .title {
  color: #1D1D1D;
  font-weight: 600;
  line-height: 1.4;
  padding: 0 20px;
  font-size: 22px;
  font-family: Montserrat, Arial, Helvetica, sans-serif;
}

.editor-content img {
  max-width: 100%;
  height: auto !important;
}

h3 ~ ul, h2 ~ ul, h1 ~ ul, p ~ ul {
  margin-top: 0 !important;
}

.page-content ul, .q-tab-panel ul {
  list-style: none;
  margin-left: 0;
  padding-left: 12px;
}
.page-content ul li, .q-tab-panel ul li {
  color: #777777;
}
.page-content ul li:before, .q-tab-panel ul li:before {
  content: "- ";
}
.page-content p, .q-tab-panel p {
  color: #777777;
}

.x-select--options .q-virtual-scroll__content .q-item {
  min-height: 35px;
}

.template-contacts {
  display: flex;
  width: 100%;
  padding: 15px 0;
}
.template-contacts .contacts-item .contacts-item-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
}
.template-contacts .contacts-item .contacts-item-phone {
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  margin: 7px 0;
}
.template-contacts .contacts-item .contacts-item-phone:hover {
  opacity: 0.8;
}
.template-contacts .contacts-item .contacts-item-phone a, .template-contacts .contacts-item .contacts-item-phone a:visited {
  color: #1F1F1F;
  text-decoration: none;
}
.template-contacts .contacts-item .contacts-item-email {
  font-size: 14px;
  line-height: 16px;
  mix-blend-mode: normal;
  opacity: 0.5;
  margin: 0 0 5px 0;
}
.template-contacts .contacts-item .contacts-item-email:hover {
  opacity: 0.7;
}
.template-contacts .contacts-item .contacts-item-email a, .template-contacts .contacts-item .contacts-item-email a:visited {
  color: #1F1F1F;
  text-decoration: none;
}
.template-contacts .contacts-item .contacts-item-addr {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin: 7px 0;
  padding-top: 12px;
  border-top: 1px solid #ccc;
}
.template-contacts .contacts-item .contacts-item-addr i {
  margin-top: -3px;
}
.template-contacts .main-contacts-block {
  width: 320px;
  margin-right: 40px;
}
.template-contacts .main-contacts-block .main-contacts-item {
  padding-top: 0;
}
.template-contacts .main-contacts-block .main-contacts-item .contacts-item-title {
  font-size: 28px;
  line-height: 28px;
}
.template-contacts .main-contacts-block .main-contacts-item .contacts-item-phone {
  font-size: 16px;
  line-height: 22px;
}
.template-contacts .more-contacts-block {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.template-contacts .more-contacts-block .more-contacts-item {
  padding: 0 20px 40px 20px;
}
.top-contacts-panel .main-row {
  margin-bottom: 24px;
}
.top-contacts-panel .items-row {
  margin-bottom: 16px;
}
.top-contacts-panel .email-row {
  margin: 16px 0;
}
.top-contacts-panel .col .top-contacts-panel-item {
  color: #1D1D1D;
  display: flex;
  align-items: center;
  padding: 8px 0;
}
.top-contacts-panel .col .top-contacts-panel-item a {
  color: #1D1D1D;
  display: flex;
  text-decoration: none;
  align-items: center;
  transition: 0.4s all ease;
  -webkit-transition: 0.4s all ease;
  -moz-transition: 0.4s all ease;
  -ms-transition: 0.4s all ease;
  -o-transition: 0.4s all ease;
  -icab-transition: 0.4s all ease;
  -khtml-transition: 0.4s all ease;
}
.top-contacts-panel .col .top-contacts-panel-item a:hover {
  color: #1279b9;
}
.top-contacts-panel .col .top-contacts-panel-item i {
  color: #1D1D1D;
  margin-right: 8px;
  font-size: 16px;
}
.top-contacts-panel .col .top-contacts-panel-item i.red {
  color: #cc0024;
}
.top-contacts-panel .col .top-contacts-panel-item i.grey {
  color: #777777;
}
.top-contacts-panel .col.col-info {
  padding-left: 32px;
}