section{
  width: 100%;
  display: flex;
  justify-content: center;

  & > .section-wrap {
    width: $site-width;

    body.screen--xs & {
      width: $site-width-xs;
    }

    body.screen--sm & {
      width: $site-width-sm;
    }

    body.screen--md & {
      width: $site-width-md;
    }

    body.screen--lg & {
      width: $site-width-lg;
    }

    body.screen--xl & {
      width: $site-width-xl;
    }
  }

  & > .section-wrap-full {
    width: 100%;
  }
}

label, .q-field--outlined {

  .q-field__control {
    &:after {
      border-width: 1px !important;
    }
  }

}

.q-btn {
  text-transform: none !important
}

.my-table {
  border-radius: 2px !important;
  border-color: #e8e8e8 !important;

  th {
    text-align: center;
  }
}

.sticky-column-table {
  width: 100%;
  max-width: 956px;
  border-radius: 2px !important;
  border-color: #e8e8e8 !important;

  &.is-full {
    max-width: 1200px;
  }

  thead tr:first-child th:first-child {
    background-color: #fff;
    border-right: 1px solid $my-grey;
  }

  td:first-child {
    //background-color: rgb(231, 231, 231);
    background-color: #fff;
    border-right: 1px solid $my-grey;
  }

  th:first-child,
  td:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
  }
}

//-----------------------
.q-checkbox.disabled {
  opacity: 0.45 !important;
}

@at-root label.q-input {

  .q-field__inner {

    .q-field__control {

      &.q-field__control {
        &::before {
          border-color: $my-grey !important;
        }

        &::after {
          border-color: $my-grey !important;
        }
      }
    }
  }
}
//-----------------------
.owl-dot {
  $size: 12px;
  opacity: 0.8;

  display: block;
  //margin: 5px;
  width: $size;
  height: $size;
  border: 2px solid $my-grey;
  border-radius: 50%;
  transition: all .25s ease;

  &.is-active {
    border-color: $dark;
    background-color: $dark;
  }
}
//-----------------------
.t-link {
  color: inherit;
  text-decoration: none;

  @include trans;

  &:hover {
    color: $blue;
  }
}
//-----------------------

.title-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 24px 0 16px;

  .title-line {
    display: block;
    flex: 1 1 auto;
    border-bottom: 1px solid rgba(129,129,129,.2);;
  }

  .title {
    color: $dark;
    font-weight: 600;
    line-height: 1.4;
    padding: 0 20px;
    font-size: 22px;
    font-family: $font-pro;
  }
}

.editor-content {
  img {
    max-width: 100%;
    height: auto !important;
  }
}



h3, h2, h1, p {

  & ~ ul {
    margin-top: 0 !important;
  }
}

.page-content, .q-tab-panel {

  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 12px;

    li {
      color: $my-dark;

      &:before {
        content: '- ';
      }
    }
  }

  p {
    color: $my-dark;
  }
}


//--------------

.x-select--options {
  .q-virtual-scroll__content {
    .q-item {
      min-height: 35px;
    }
  }
}
