.template-contacts {
  display: flex;
  width: 100%;
  padding: 15px 0;

  .contacts-item {

    .contacts-item-title {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
    }

    .contacts-item-phone {
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
      margin: 7px 0;

      &:hover {
        opacity: 0.8;
      }

      a, a:visited {
      color: #1F1F1F;
      text-decoration: none;
    }
    }

    .contacts-item-email {
      font-size: 14px;
      line-height: 16px;
      mix-blend-mode: normal;
      opacity: 0.5;
      margin: 0 0 5px 0;

      &:hover {
        opacity: 0.7;
      }

      a, a:visited {
        color: #1F1F1F;
        text-decoration: none;
      }
    }

    .contacts-item-addr {
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      margin: 7px 0;

      padding-top: 12px;
      border-top: 1px solid #ccc;

      i {
        margin-top: -3px
      }
    }
  }

  .main-contacts-block {
    width: 320px;
    margin-right: 40px;;

    .main-contacts-item {
      padding-top: 0;

      .contacts-item-title {
        font-size: 28px;
        line-height: 28px;
      }

      .contacts-item-phone {
        font-size: 16px;
        line-height: 22px;
      }

      .contacts-item-addr {

      }
    }
  }

  .more-contacts-block {
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .more-contacts-item {
      padding: 0 20px 40px 20px;

      .contacts-item-phone {

      }
    }
  }
}
